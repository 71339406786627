<template>
  <div class="editproject mt-5">
    <r-section :label="$t('projects.editProject.title')">
      <template v-slot:content>
        <v-form
          ref="form"
          v-model="valid"
        >
          <r-error-message :errors="[fetchEditProjectError]" />

          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              sm="6"
              md="6"
              lg="6"
              class="pr-2"
            >
              <r-text-field
                class="input-text editproject--name"
                :value="name"
                :label="$t('projects.name.label')"
                :placeholder="$t('projects.name.placeholder')"
                :rules="[rules.required]"
                :disabled="!isEditable"
                @change="onChangeProjectName"
              />
              <r-text-field
                class="input-text editproject--description"
                type="text-area"
                :value="description"
                :label="$t('projects.description.label')"
                :placeholder="$t('projects.description.placeholder')"
                :disabled="!isEditable"
                @change="onChangeProjectDescription"
              />
            </v-col>

            <v-col
              cols="6"
              offset="0"
              sm="6"
              class="pl-2"
            >
              <div
                v-if="isEditable"
                class="d-flex align-end justify-start mb-4"
              >
                <label class="mr-1 labeltitle">{{ $t('projects.setUpDate.label') }}</label>
                <label class="ml-1">{{ createdAt }}</label>
              </div>

              <div class="d-flex align-end justify-start mb-4">
                <label class="mr-1 labeltitle">{{ $t('projects.numOfURLs.label') }}</label>
                <label class="ml-1">{{ numUrls }}</label>
              </div>

              <div class="d-flex align-end justify-start mb-4">
                <label class="mr-1 labeltitle">{{ $t('projects.numOfProducts.label') }}</label>
                <label class="ml-1">{{ numProducts }}</label>
              </div>

              <div class="d-flex align-end justify-start mb-4">
                <label class="mr-1 labeltitle">{{ $t('projects.numOfDashboards.label') }}</label>
                <label class="ml-1">{{ numDashboards }}</label>
              </div>

              <v-divider />

              <div class="py-5">
                <r-button
                  class="editproject--delete"
                  :outlined="true"
                  :label="$t('projects.delete.label')"
                  :loading="fetchingEditProject"
                  :disabled="!isEditable || !valid"
                  @click="onClickDeleteProject"
                />
              </div>

              <v-divider />
            </v-col>
          </v-row>

          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <div class="d-flex align-center justify-end">
                <r-button
                  class="mr-2 editproject--cancel"
                  color="normal"
                  :label="$t('global.actions.cancel')"
                  @click="onClickCancel"
                />
                <r-button
                  class="editproject--save"
                  :label="$t('global.actions.save')"
                  :loading="fetchingEditProject"
                  :disabled="!isEditable || !valid"
                  @click="onClickEditProject"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </r-section>

    <r-modal
      v-if="showDeleteProjectModal"
      :title="$t('projects.delete.confirmTitle')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('global.actions.confirm')"
      :fetching="fetchingDeleteProjects"
      :action-completed="false"
      @submit="onDeleteProject"
      @close="toggleDeleteProjectModal"
    >
      <template v-slot:content>
        <div>
          <r-error-message :errors="[fetchingDeleteProjectError]" />
          <label>{{ $t('projects.delete.confirmMessage') }}</label>
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RTextField from '@/components/library/molecules/RTextField'
import RSection from '@/components/library/molecules/RSection'
import RButton from '@/components/library/atoms/RButton'
import RModal from '@/components/library/organisms/RModal'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'

import formatDate from '@/utils/formatDate'

import { mapActions, mapState } from 'vuex'
import { ALL_DATA_PROJECT } from '@/utils/constants'

export default {
  name: 'EditProject',
  components: {
    RErrorMessage,
    RTextField,
    RSection,
    RButton,
    RModal,
  },
  data() {
    return {
      valid: false,
      name: '',
      description: '',
      numUrls: 0,
      numProducts: 0,
      numDashboards: 0,
      created: '',
      showDeleteProjectModal: false,
      rules: {
        required: (v) => !!v || this.$i18n.t('global.validations.messages.required'),
      },
    }
  },
  computed: {
    ...mapState('projects', [
      'editingProject',
      'fetchingEditProject',
      'fetchEditProjectError',
      'fetchingDeleteProjects',
      'fetchingProjects',
      'fetchingDeleteProjectError',
    ]),
    createdAt() {
      const { $moment } = this
      const date = this.created
      const format = 'DD.MM.YYYY'
      return formatDate({
        $moment,
        date,
        format,
      })
    },
    isEditable() {
      return ALL_DATA_PROJECT.id !== this.$route.params.projectId
    },
  },
  watch: {
    editingProject(project) {
      this.loadProject(project)
    },
  },
  mounted() {
    const { projectId } = this.$route.params
    this.fetchProject({ projectId })
  },
  beforeDestroy() {
    this.resetErrors({ fetchEditProjectError: '' })
  },
  methods: {
    ...mapActions('projects', [
      'resetErrors',
      'editProject',
      'fetchProject',
      'deleteProject',
      'fetchProjects',
    ]),
    async onDeleteProject() {
      const { projectId } = this.$route.params
      await this.deleteProject({ projectId })
      await this.fetchProjects()
      await this.$router.push('/data')
    },
    toggleDeleteProjectModal() {
      this.resetErrors({ fetchingDeleteProjectError: '' })
      this.showDeleteProjectModal = !this.showDeleteProjectModal
    },
    onClickDeleteProject(e) {
      e.preventDefault()
      this.toggleDeleteProjectModal()
    },
    onChangeProjectName({ value: name }) {
      this.name = name
    },
    onChangeProjectDescription({ value: description }) {
      this.description = description
    },
    onClickEditProject(e) {
      e.preventDefault()

      if (!this.$refs.form.validate() || this.fetchingEditProject) {
        return
      }

      const project = {
        id: this.$route.params.projectId,
        name: this.name,
        description: this.description,
      }

      this.editProject({ project }).then(() => {
        if (!this.fetchEditProjectError) {
          this.$router.push('/data')
        }
      })
    },
    onClickCancel(e) {
      e.preventDefault()

      this.$router.push('/data')
    },
    loadProject(project) {
      if (!project) {
        return
      }

      const {
        name,
        description,
        numUrls,
        numProducts,
        numDashboards,
        created,
      } = project

      this.name = name
      this.description = description
      this.numUrls = numUrls
      this.numProducts = numProducts
      this.numDashboards = numDashboards
      this.created = created
    },
  },
}
</script>

<style scoped>
.labeltitle {
  font-weight: bold;
}

form.v-form {
  margin-top: 40px;
}

/deep/ .rselect {
  width: 200px;
}

/deep/ textarea {
  height: 220px;
}
</style>
