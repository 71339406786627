var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editproject mt-5"
  }, [_c('r-section', {
    attrs: {
      "label": _vm.$t('projects.editProject.title')
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-form', {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchEditProjectError]
          }
        }), _c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "pr-2",
          attrs: {
            "sm": "6",
            "md": "6",
            "lg": "6"
          }
        }, [_c('r-text-field', {
          staticClass: "input-text editproject--name",
          attrs: {
            "value": _vm.name,
            "label": _vm.$t('projects.name.label'),
            "placeholder": _vm.$t('projects.name.placeholder'),
            "rules": [_vm.rules.required],
            "disabled": !_vm.isEditable
          },
          on: {
            "change": _vm.onChangeProjectName
          }
        }), _c('r-text-field', {
          staticClass: "input-text editproject--description",
          attrs: {
            "type": "text-area",
            "value": _vm.description,
            "label": _vm.$t('projects.description.label'),
            "placeholder": _vm.$t('projects.description.placeholder'),
            "disabled": !_vm.isEditable
          },
          on: {
            "change": _vm.onChangeProjectDescription
          }
        })], 1), _c('v-col', {
          staticClass: "pl-2",
          attrs: {
            "cols": "6",
            "offset": "0",
            "sm": "6"
          }
        }, [_vm.isEditable ? _c('div', {
          staticClass: "d-flex align-end justify-start mb-4"
        }, [_c('label', {
          staticClass: "mr-1 labeltitle"
        }, [_vm._v(_vm._s(_vm.$t('projects.setUpDate.label')))]), _c('label', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.createdAt))])]) : _vm._e(), _c('div', {
          staticClass: "d-flex align-end justify-start mb-4"
        }, [_c('label', {
          staticClass: "mr-1 labeltitle"
        }, [_vm._v(_vm._s(_vm.$t('projects.numOfURLs.label')))]), _c('label', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.numUrls))])]), _c('div', {
          staticClass: "d-flex align-end justify-start mb-4"
        }, [_c('label', {
          staticClass: "mr-1 labeltitle"
        }, [_vm._v(_vm._s(_vm.$t('projects.numOfProducts.label')))]), _c('label', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.numProducts))])]), _c('div', {
          staticClass: "d-flex align-end justify-start mb-4"
        }, [_c('label', {
          staticClass: "mr-1 labeltitle"
        }, [_vm._v(_vm._s(_vm.$t('projects.numOfDashboards.label')))]), _c('label', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.numDashboards))])]), _c('v-divider'), _c('div', {
          staticClass: "py-5"
        }, [_c('r-button', {
          staticClass: "editproject--delete",
          attrs: {
            "outlined": true,
            "label": _vm.$t('projects.delete.label'),
            "loading": _vm.fetchingEditProject,
            "disabled": !_vm.isEditable || !_vm.valid
          },
          on: {
            "click": _vm.onClickDeleteProject
          }
        })], 1), _c('v-divider')], 1)], 1), _c('v-row', {
          staticClass: "mt-5",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-end"
        }, [_c('r-button', {
          staticClass: "mr-2 editproject--cancel",
          attrs: {
            "color": "normal",
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.onClickCancel
          }
        }), _c('r-button', {
          staticClass: "editproject--save",
          attrs: {
            "label": _vm.$t('global.actions.save'),
            "loading": _vm.fetchingEditProject,
            "disabled": !_vm.isEditable || !_vm.valid
          },
          on: {
            "click": _vm.onClickEditProject
          }
        })], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  }), _vm.showDeleteProjectModal ? _c('r-modal', {
    attrs: {
      "title": _vm.$t('projects.delete.confirmTitle'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('global.actions.confirm'),
      "fetching": _vm.fetchingDeleteProjects,
      "action-completed": false
    },
    on: {
      "submit": _vm.onDeleteProject,
      "close": _vm.toggleDeleteProjectModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchingDeleteProjectError]
          }
        }), _c('label', [_vm._v(_vm._s(_vm.$t('projects.delete.confirmMessage')))])], 1)];
      },
      proxy: true
    }], null, false, 1173543063)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }